import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu'
// import animate from 'css-animation'

import {
  LinkGold,
  LinkGoldClean,
  LinkGoldCleanAllPosts,
  DropdownMenuContainer,
} from '../Styled'
import GlobalStyles from '../../styles/globalStyles'
import { capitalize } from '../../utils/helpers'

/*
function handleClick(info) {
  console.log(`clicked ${info.key}`);
  console.log(info);
}

const animation = {
  enter(node, done) {
    let height
    return animate(node, 'rc-menu-collapse', {
      start() {
        height = node.offsetHeight
        node.style.height = 0
      },
      active() {
        node.style.height = `${height}px`
      },
      end() {
        node.style.height = ''
        done()
      },
    })
  },

  appear() {
    return this.enter.apply(this, arguments)
  },

  leave(node, done) {
    return animate(node, 'rc-menu-collapse', {
      start() {
        node.style.height = `${node.offsetHeight}px`
      },
      active() {
        node.style.height = 0
      },
      end() {
        node.style.height = ''
        done()
      },
    })
  },
}

const nestSubMenu = (
  <SubMenu
    title={<span className="submenu-title-wrapper margin-class">Guides</span>}
    key="5">
    <MenuItem key="5-1">
      <Link to="/posts" onClick={() => setIsMenuOpen(false)}>
        Shirt
      </Link>
    </MenuItem>
    <MenuItem key="5-2-0-1">Suit</MenuItem>
    <MenuItem key="5-2-0-1">Construction</MenuItem>
    <MenuItem key="5-2-2-1">Fabric</MenuItem>
    <MenuItem key="5-2-3-1">Fit</MenuItem>
  </SubMenu>
)

function onOpenChange(value) {
  console.log('onOpenChange', value);
}
*/

const children1 = (tags) => [
  <MenuItem key="1">
    <Link to="/">
      <LinkGold>Home</LinkGold>
    </Link>
  </MenuItem>,
  <SubMenu
    title={
      <span className="submenu-title-wrapper">
        <LinkGold>Posts</LinkGold>
      </span>
    }
    key="2">
    <MenuItem key="1-1">
      <Link to="/posts/">
        <LinkGoldCleanAllPosts>All posts</LinkGoldCleanAllPosts>
      </Link>
      {tags.map((tag) => (
        <Link key={tag.name} to={`/tags/${tag.name}`}>
          <LinkGoldClean>{capitalize(tag.name)}</LinkGoldClean>
        </Link>
      ))}
    </MenuItem>
  </SubMenu>,
  <MenuItem key="3">
    <Link to="/about">
      <LinkGold>About</LinkGold>
    </Link>
  </MenuItem>,
  <MenuItem key="4">
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.instagram.com/tuxedosnob/">
      <LinkGold>Instagram</LinkGold>
    </a>
  </MenuItem>,
]

const customizeIndicator = <span>Add More Items</span>

class DropdownMenu extends React.Component {
  state = {
    children: children1(this.props.tags),
    overflowedIndicator: undefined,
  }

  toggleChildren = () => {
    this.setState({
      children: children1(this.props.tags),
    })
  }

  toggleOverflowedIndicator = () => {
    this.setState({
      overflowedIndicator:
        this.state.overflowedIndicator === undefined
          ? customizeIndicator
          : undefined,
    })
  }

  render() {
    const { triggerSubMenuAction } = this.props
    const { children, overflowedIndicator } = this.state
    return (
      <>
        <GlobalStyles />
        <DropdownMenuContainer>
          {this.props.updateChildrenAndOverflowedIndicator && (
            <div>
              <button onClick={this.toggleChildren}>toggle children</button>
              <button onClick={this.toggleOverflowedIndicator}>
                toggle overflowedIndicator
              </button>
            </div>
          )}
          <Menu
            // onClick={handleClick}
            triggerSubMenuAction={triggerSubMenuAction}
            // onOpenChange={onOpenChange}
            mode={this.props.mode}
            openAnimation={this.props.openAnimation}
            defaultOpenKeys={this.props.defaultOpenKeys}
            overflowedIndicator={overflowedIndicator}>
            {children}
          </Menu>
        </DropdownMenuContainer>
      </>
    )
  }
}

DropdownMenu.propTypes = {
  mode: PropTypes.string,
  openAnimation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  triggerSubMenuAction: PropTypes.string,
  defaultOpenKeys: PropTypes.arrayOf(PropTypes.string),
  updateChildrenAndOverflowedIndicator: PropTypes.bool,
  tags: PropTypes.array,
}

export default DropdownMenu
