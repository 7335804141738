import React from 'react' // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Link, graphql, StaticQuery } from 'gatsby'
import Navigation from 'react-sticky-nav'

import {
  CoverImage,
  CoverImageWrapper,
  MenuContainer,
  TextLogo,
} from '../Styled'
import DropdownMenu from '../DropdownMenu'
import { getAllTagsSorted } from '../../utils/helpers'

const MainHeader = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark {
          edges {
            node {
              frontmatter {
                tags
              }
            }
          }
        }
        cover: file(relativePath: { eq: "cover.png" }) {
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => {
      const allTags = getAllTagsSorted(data)
      return (
        <>
          <Link to="/posts">
            <TextLogo>Tuxedo Snob</TextLogo>
          </Link>
          <Navigation
            css={css`
              z-index: 1;
              position: -webkit-sticky; /* This is needed for Safari support */
            `}>
            {/* <MenuContainer>
              <DropdownMenu
                mode="horizontal"
                openAnimation="slide-up"
                tags={allTags}
              />
            </MenuContainer> */}
          </Navigation>
          {/* <CoverImageWrapper>
            <CoverImage
              fluid={data.cover.childImageSharp.fluid}
              alt="cover image"
            />
          </CoverImageWrapper> */}
        </>
      )
    }}
  />
)

export default MainHeader
