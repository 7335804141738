import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Navigation from '../components/Navigation'
import { Signature } from './styles'
import GlobalStyles from '../styles/globalStyles'

const Layout = ({ title, children }) => [
  <GlobalStyles key="global-styles" />,
  <Helmet key="app-head" titleTemplate="%s · David K." defaultTitle="David K.">
    <meta charSet="utf-8" />
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    <title>{title}</title>
  </Helmet>,
  // <Navigation key="app-main">
  <div>
    <div>{children}</div>
    <Signature>
      © <span>{`${new Date().getFullYear()} David K.`}</span>
    </Signature>
  </div>,
  // </Navigation>,
]

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
