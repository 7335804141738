const sortTagsAlpha = (tags) =>
  tags.sort((a, b) => a.name.localeCompare(b.name))

const getAllTags = (data) =>
  data.allMarkdownRemark.edges.reduce(
    (acc, val) => [
      ...acc,
      ...val.node.frontmatter.tags.map((tag) => ({
        name: tag,
        count: 0,
      })),
    ],
    [],
  )

const removeDuplicates = (tags) =>
  tags.reduce((acc, val) => {
    const tagExists = acc.find((tag) => tag.name === val.name)
    if (tagExists) {
      return acc.map((tag) =>
        tag.name === val.name ? { ...tag, count: tag.count + 1 } : tag,
      )
    }
    return [...acc, { name: val.name, count: 1 }]
  }, [])

export const getAllTagsSorted = (data) =>
  sortTagsAlpha(removeDuplicates(getAllTags(data)))

export const capitalize = (string) =>
  string.replace(/^./, string[0].toUpperCase())
