import styled from '@emotion/styled'
import { css } from '@emotion/core'
import mq from '../../utils/styling'

export const navigationStyles = {
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmItem: {
    letterSpacing: '2px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bmMenuWrap: {
    padding: '0 15px 50px',
    marginBottom: '150px',
    textAlign: 'center',
    backgroundColor: 'white',
  },
}

export const MenuItem = styled.div`
  font-family: PT Sans Narrow;
  ${mq.large(css`
    margin-bottom: 20px;
  `)};
`
export const MenuContainer = styled.div`
  font-size: 1.2rem;
`

export const InnerContainer = styled.div`
  z-index: 9999999;
  position: fixed;
  right: 0;
  top: 29px;
  height: 40px;
  padding: 0;
  width: 40px;
  -webkit-appearance: none;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  ${mq.medium(css`
    display: none;
    top: 36px;
  `)};
`

export const TimesIconContainer = styled.div`
  align-self: flex-end;
  margin-top: 20px;
  cursor: pointer;
`
