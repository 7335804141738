import React from 'react'
import PropTypes from 'prop-types'
import HamburgerMenu from 'react-hamburger-menu'
import { Link } from 'gatsby'
import { withState } from 'recompose'
import { push as Menu } from 'react-burger-menu'
import { FaTimes } from 'react-icons/fa'
import { LinkGold } from '../Styled'
import {
  MenuItem,
  MenuContainer,
  InnerContainer,
  TimesIconContainer,
  navigationStyles,
} from './styles'

const Navigation = ({ children, isMenuOpen, setIsMenuOpen }) => (
  <div id="outer-container">
    <Menu
      right
      isOpen={isMenuOpen}
      customBurgerIcon={false}
      customCrossIcon={false}
      onStateChange={(state) => setIsMenuOpen(state.isOpen)}
      styles={navigationStyles}
      pageWrapId="page-wrap"
      outerContainerId="outer-container">
      <MenuContainer>
        <TimesIconContainer onClick={() => setIsMenuOpen(false)}>
          <FaTimes size={25} />
        </TimesIconContainer>
        <MenuItem>
          <Link to="/" onClick={() => setIsMenuOpen(false)}>
            <LinkGold>Home</LinkGold>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/posts" onClick={() => setIsMenuOpen(false)}>
            <LinkGold>Posts</LinkGold>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/about" onClick={() => setIsMenuOpen(false)}>
            <LinkGold>About</LinkGold>
          </Link>
        </MenuItem>
        <MenuItem>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/tuxedosnob/">
            <LinkGold>Instagram</LinkGold>
          </a>
        </MenuItem>
      </MenuContainer>
    </Menu>
    {!isMenuOpen && (
      <InnerContainer>
        <HamburgerMenu
          isOpen={isMenuOpen}
          menuClicked={() => setIsMenuOpen(!isMenuOpen)}
          width={25}
          height={25}
          strokeWidth={3}
          borderRadius={1}
          animationDuration={0.5}
        />
      </InnerContainer>
    )}
    <main id="page-wrap">{children}</main>
  </div>
)

Navigation.propTypes = {
  children: PropTypes.node.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
}

export default withState('isMenuOpen', 'setIsMenuOpen', false)(Navigation)
