import styled from '@emotion/styled'
import { css } from '@emotion/core'
import mq from '../utils/styling'

// eslint-disable-next-line import/prefer-default-export
export const Signature = styled.div`
  text-align: center;
  font-size: 0.6rem;
  ${mq.large(css`
    margin: 2.2rem 0;
  `)};
`
